import style from "./input.module.scss";
import { InputCheckIcon, PasswordEye } from "../../assets/icon";
import { useEffect, useState } from "react";
import { Field } from "formik";
// import cities from "cities.json";
import countries from "../../../node_modules/countries-list/dist/countries.emoji.json";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { TurkeyCity } from "../../data/TurkeyCityData";
import { ImageIcon, FileIcon } from "../../assets/icon";
import { LangData } from "../../data/LangData";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import DocViewer from "react-doc-viewer";

const TextInput = ({ title, placeholder, name, value }) => {
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{title}</h5>
      <Field
        type={"text"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
      />
    </label>
  );
};

const TextAreaInput = ({ title, placeholder, name, value }) => {
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{title}</h5>
      <Field
        as={"textarea"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
      />
    </label>
  );
};

const ProfileImageInput = ({
  title,
  placeholder,
  name,
  value,
  file,
  setFile,
}) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.profileImage} `}>
      <h5>{LangData[lang].form.profileImage}</h5>
      <div className={style.photoCircle}>
        {file && <img src={URL.createObjectURL(file)} />}
        <ImageIcon />
        <input
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
          accept="image/*"
        />
      </div>
    </label>
  );
};

const FileAddInput = ({ title, placeholder, name, value, change, single }) => {
  const [file, setFile] = useState(
    value
      ? [
          {
            file: value.documents[0],
            title: "",
          },
        ]
      : []
  );
  const [currentFile, setCurrentFile] = useState({
    file: "",
    title: "",
  });

  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.fileInput} `}>
      <h5>{title ? title : LangData[lang].form.document}</h5>
      <div className={style.inputWrapper}>
        <div className={style.fileWrapper}>
          <h6>
            <FileIcon />{" "}
            <span>
              {" "}
              {currentFile.file && currentFile.file.name
                ? currentFile.file.name
                : currentFile.file
                ? currentFile.file
                : placeholder
                ? placeholder
                : LangData[lang].form.documentFile}
            </span>
          </h6>
          <input
            onChange={(e) => {
              setCurrentFile({
                ...currentFile,
                file: e.target.files[0],
              });
              change(e.target.files[0]);
            }}
            type="file"
            disabled={single && file.length}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          />
        </div>
      </div>
      <button
        onClick={async (e) => {
          await e.preventDefault();
          if (currentFile.file) {
            await setFile([...file, currentFile]);
            await setCurrentFile({
              file: "",
              title: "",
            });
          }
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addDocument}
      </button>
      {file && file.length ? (
        <div className={style.rewardWrapper}>
          {file.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={() => {
                    setFile(file.filter((i, indexI) => indexI !== index));
                  }}
                >
                  X
                </button>
                <h3>{item.file.name ? item.file.name : placeholder}</h3>
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const OperationAddInput = ({
  file,
  fileChange,
  title,
  placeholder,
  name,
  value,
  deleteClick,
}) => {
  const [currentFile, setCurrentFile] = useState({
    file: [],
    title: "",
  });

  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.fileInput} `}>
      <h5>{LangData[lang].form.operation}</h5>
      <div className={style.inputWrapper}>
        <label className={style.input}>
          <input
            onChange={(e) =>
              setCurrentFile({ ...currentFile, title: e.target.value })
            }
            type="text"
            placeholder={LangData[lang].form.operationTitle}
          />
        </label>
        <div className={style.fileWrapper}>
          <h6>
            <ImageIcon /> {LangData[lang].form.operationImage}
          </h6>
          <input
            onChange={(e) =>
              setCurrentFile({
                ...currentFile,
                file: [...currentFile.file, ...e.target.files],
              })
            }
            type="file"
            accept="image/*"
            multiple
          />
        </div>
        {currentFile.file && currentFile.file.length ? (
          <div className={style.imageWrapper}>
            {currentFile.file.map((item) => {
              return <img src={URL.createObjectURL(item)} />;
            })}
          </div>
        ) : null}
      </div>
      <button
        onClick={async (e) => {
          await e.preventDefault();
          if (currentFile.file && currentFile.title) {
            await fileChange(currentFile);
            await setCurrentFile({
              file: [],
              title: "",
            });
          }
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addOperationImage}
      </button>
      {file && file.length ? (
        <div className={style.rewardWrapper}>
          {file.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    deleteClick(index);
                  }}
                >
                  X
                </button>
                <h2>{item.title}</h2>
                {item.file && item.file.length ? (
                  <div className={style.imageWrapper}>
                    {item.file.map((itemImage) => {
                      return (
                        <img
                          src={
                            typeof itemImage === "string"
                              ? itemImage
                              : URL.createObjectURL(itemImage)
                          }
                        />
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const SelectInput = ({
  title,
  placeholder,
  name,
  value,
  valueData,
  doctor,
}) => {
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{title}</h5>
      <Field
        as={"select"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
      >
        {valueData.map((item, index) => {
          return (
            <option disabled={doctor && index === 0} value={item.value}>
              {item.name}
            </option>
          );
        })}
      </Field>
    </label>
  );
};

const EmailInput = ({ name, value, disabled }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.email}</h5>
      <Field
        id={name}
        name={name}
        value={value}
        type={"email"}
        disabled={disabled}
      />
    </label>
  );
};

const PhoneInput = ({ name, value }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.phone}</h5>
      <Field
        id={name}
        name={name}
        value={value}
        type={"tel"}
        placeholder={LangData[lang].form.phonePlaceholder}
        pattern="^[0-9]{3,45}$"
      />
    </label>
  );
};

const PasswordInput = ({ again, value, name }) => {
  const [isOn, setOn] = useState(false);
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.input} ${style.email} ${style.password}`}>
      <h5>
        {again
          ? LangData[lang].form.passwordAgain
          : LangData[lang].form.password}
      </h5>
      <Field
        value={value}
        id={name}
        name={name}
        type={isOn ? "text" : "password"}
        placeholder={LangData[lang].form.passwordPlaceholder}
      />
      <button type="button" onClick={() => setOn(!isOn)}>
        <PasswordEye isOn={isOn} />
      </button>
    </label>
  );
};

const DateInput = ({ name, value, date, change }) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const [open, setOpen] = useState(false);
  return (
    <div className={`${style.date} `}>
      <h5>{LangData[lang].form.birthday}</h5>
      <label htmlFor="date">
        <h6>
          {date && typeof date !== "object"
            ? `${date.split("T")[0].split("-")[2]}-${
                date.split("T")[0].split("-")[1]
              }-${date.split("T")[0].split("-")[0]}`
            : date
            ? `${date.$D < 9 ? `0${date.$D}` : date.$D}-${
                date.$M < 9 ? `0${date.$M + 1}` : date.$M + 1
              }-${date.$y}`
            : LangData[lang].form.date}
        </h6>
        <div className={style.dateWrapper}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              open={open}
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                  onClick: () => setOpen(true),
                },
              }}
              onChange={change}
              value={dayjs(value)}
              maxDate={dayjs(new Date(Date.now() - 567648000000))}
            />
          </LocalizationProvider>
        </div>
        {/* <Field
          // onChange={e => {
          //   setSelectedDate(e.target.value);
          // }}
          type={'date'}
          value={value}
          id={name}
          name={name}
        /> */}
      </label>
    </div>
  );
};
const RememberMe = () => {
  const [isChecked, setChecked] = useState();
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <div className={style.remember}>
      <label className={isChecked && style.checked}>
        <InputCheckIcon isChecked={isChecked} />
        <input
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          type={"checkbox"}
        />
        <h5>{LangData[lang].form.remember}</h5>
      </label>
      <a href="/forgot-password">{LangData[lang].form.forgotPassword}</a>
    </div>
  );
};

const RegionInput = ({ title, placeholder, name, value, valueData }) => {
  var countriesList = Object.keys(countries).map((key) => [
    key,
    countries[key],
  ]);
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{title}</h5>
      <Field
        as={"select"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
      >
        <option selected>{placeholder}</option>
        <option value={"TR"}>🇹🇷 Türkiye</option>
        {countriesList
          .filter((i) => i[0] !== "TR")
          .map((item) => {
            return (
              <option value={item[0]}>
                {item[1].emoji} {item[1].name}
              </option>
            );
          })}
      </Field>
    </label>
  );
};

const CityInput = ({ title, placeholder, name, value, valueData, data }) => {
  // const cityData = data ? cities.filter((i) => i.country === data) : null;

  // console.log("value", value);
  const lang = useSelector((state) => state.settings.setting.lang);
  const [cityData, setCityData] = useState();

  console.log("countryData", data);

  const getCityData = (value) => {
    axios
      .post(`${ApiRoutes.search.city}?country=${value}&lang=${lang}`)
      .then((res) => {
        console.log(res.data);
        const rawData = JSON.parse(res.data.data);
        console.log("rawData", rawData);
        if (rawData && rawData.length) {
          setCityData(rawData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCityData(data);
  }, [data]);

  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{title}</h5>
      <Field
        as={"select"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={!data}
      >
        <option value={""} disabled selected>
          {placeholder}
        </option>
        {cityData && cityData.length
          ? data && data !== "TR"
            ? cityData.sort().map((item) => {
                return <option value={item}>{item}</option>;
              })
            : TurkeyCity.sort().map((item) => {
                return <option value={item}>{item}</option>;
              })
          : null}
      </Field>
    </label>
  );
};

const RewardInput = ({ name, data, addData, deleteClick }) => {
  const [info, setInfo] = useState({
    caption: "",
    detail: "",
    file: "",
  });
  const lang = useSelector((state) => state.settings.setting.lang);

  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.reward}</h5>
      <Field
        type={"text"}
        id={"reward1"}
        name={"reward1"}
        placeholder={LangData[lang].form.captionPlaceholder}
        value={info.caption}
        onChange={(e) => {
          setInfo({
            ...info,
            caption: e.target.value,
          });
        }}
      />
      <Field
        type={"text"}
        id={"reward2"}
        name={"reward2"}
        placeholder={LangData[lang].form.detailPlaceholder}
        value={info.detail}
        onChange={(e) => {
          setInfo({
            ...info,
            detail: e.target.value,
          });
        }}
      />
      <div className={style.inputWrapper}>
        <div className={style.fileWrapper}>
          <h6>
            <FileIcon />{" "}
            {info.file ? info.file.name : LangData[lang].form.rewardFile}
          </h6>
          <input
            onChange={(e) => {
              setInfo({
                ...info,
                file: e.target.files[0],
              });
            }}
            type="file"
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          />
        </div>
      </div>
      <button
        onClick={async (e) => {
          await e.preventDefault();
          if (info.caption && info.detail) {
            await addData(info);
          }
          await setInfo({
            caption: "",
            detail: "",
            file: "",
          });
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addReward}
      </button>

      {data && data.length ? (
        <div className={style.rewardWrapper}>
          {data.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    deleteClick(index);
                  }}
                >
                  X
                </button>
                <h2>{item.caption}</h2>
                <h3>{item.detail}</h3>
                <div className={style.fileInfoWrapper}>
                  <h5>File</h5>
                  <h3>{item.file && item.file.name ? item.file.name : ""}</h3>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const RewardWithFileInput = ({ name, data, addData, deleteClick }) => {
  const [info, setInfo] = useState({
    caption: "",
    detail: "",
    file: "",
  });
  const lang = useSelector((state) => state.settings.setting.lang);
  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.reward}</h5>
      <Field
        type={"text"}
        id={"reward1"}
        name={"reward1"}
        placeholder={LangData[lang].form.captionPlaceholder}
        value={info.caption}
        onChange={(e) => {
          setInfo({
            ...info,
            caption: e.target.value,
          });
        }}
      />
      <Field
        type={"text"}
        id={"reward2"}
        name={"reward2"}
        placeholder={LangData[lang].form.detailPlaceholder}
        value={info.detail}
        onChange={(e) => {
          setInfo({
            ...info,
            detail: e.target.value,
          });
        }}
      />
      <div className={style.inputWrapper}>
        <div className={style.fileWrapper}>
          <h6>
            <FileIcon />{" "}
            {info.file ? info.file.name : LangData[lang].form.documentFile}
          </h6>
          <input
            onChange={(e) => {
              setInfo({
                ...info,
                file: e.target.files[0],
              });
            }}
            type="file"
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
          />
        </div>
      </div>
      <button
        onClick={async (e) => {
          await e.preventDefault();
          if (info.caption && info.detail && info.file) {
            await addData(info);
            await setInfo({
              caption: "",
              detail: "",
              file: "",
            });
          }
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addReward}
      </button>

      {data && data.length ? (
        <div className={style.rewardWrapper}>
          {data.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    deleteClick(index);
                  }}
                >
                  X
                </button>
                <h2>{item.caption}</h2>
                <h3>{item.detail}</h3>
                <div className={style.fileInfoWrapper}>
                  <h5>File</h5>
                  <h3>{item.file.name}</h3>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const OperationInput = ({
  title,
  placeholder,
  name,
  value,
  addData,
  deleteClick,
}) => {
  const [operationData, setOperationData] = useState();
  const [currentOperation, setCurrentOperation] = useState({
    category: "",
    operation: "",
  });
  const [type, setType] = useState("new");
  const [categoryData, setCategoryData] = useState();
  const lang = useSelector((state) => state.settings.setting.lang);

  console.log("operasyon", operationData);

  const getOperationData = (value) => {
    console.log("girdi");
    axios
      .post(
        `${ApiRoutes.search.operation.getByCategory}?id=${value}&lang=${lang}`
      )
      .then((res) => {
        if (res.data.caption === "Success") {
          const operationRaw = JSON.parse(res.data.data);
          console.log(operationRaw);
          setOperationData(operationRaw);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${ApiRoutes.search.category.get}?lang=${lang}`)
      .then((res) => {
        if (res.data.caption === "Success") {
          const categoryRaw = JSON.parse(res.data.data);
          setCategoryData(categoryRaw);
          setCurrentOperation({
            category: {
              id: categoryRaw[0].Id,
              name: categoryRaw[0].Name,
            },
            operation: "",
          });
          getOperationData(categoryRaw[0].Id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.categoryOperation}</h5>
      <Field
        as={"select"}
        id={name}
        name={name}
        placeholder={placeholder}
        value={`${currentOperation.category.id}&${currentOperation.category.name}`}
        disabled={!categoryData}
        onChange={(e) => {
          setCurrentOperation({
            operation: "",
            category: {
              id: e.target.value.split("&")[0],
              name: e.target.value.split("&")[1],
            },
          });

          console.log(e.target.value);

          if (e.target.value) {
            getOperationData(e.target.value.split("&")[0]);
          }
        }}
      >
        <option value={""} disabled selected>
          {LangData[lang].form.selectCategory}
        </option>
        {categoryData &&
          categoryData.map((item) => {
            return (
              <option value={`${item.Id}&${item.Name}`}>{item.Name}</option>
            );
          })}
      </Field>
      <select
        onChange={(e) => {
          setType(e.target.value);
          setCurrentOperation({
            ...currentOperation,
            operation: "",
          });
        }}
      >
        <option value={"new"}>{LangData[lang].form.newOperation}</option>
        <option value={"select"}>{LangData[lang].form.selection}</option>
      </select>
      {type === "new" ? (
        <input
          onChange={(e) => {
            setCurrentOperation({
              ...currentOperation,
              operation: {
                id: 0,
                name: e.target.value,
              },
            });
          }}
          type="text"
          placeholder={LangData[lang].form.operationDetail}
        />
      ) : (
        <>
          {operationData && (
            <Field
              as={"select"}
              id={name}
              name={name}
              placeholder={placeholder}
              value={
                currentOperation.operation
                  ? `${currentOperation.operation.id}&${currentOperation.operation.name}`
                  : ""
              }
              disabled={!operationData}
              onChange={(e) => {
                setCurrentOperation({
                  ...currentOperation,
                  operation: {
                    id: e.target.value.split("&")[0],
                    name: e.target.value.split("&")[1],
                  },
                });
              }}
            >
              <option value={""} disabled selected>
                {LangData[lang].form.selectOperation}
              </option>
              {operationData &&
                currentOperation.category &&
                operationData.map((item) => {
                  return (
                    <option value={`${item.Id}&${item.Name}`}>
                      {item.Name}
                    </option>
                  );
                })}
            </Field>
          )}
        </>
      )}
      <button
        onClick={async (e) => {
          await e.preventDefault();

          if (currentOperation.category && currentOperation.operation) {
            await addData(currentOperation);
            await setCurrentOperation({
              ...currentOperation,
              operation: "",
            });
          }
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addOperation}
      </button>
      {value && value.length ? (
        <div className={style.rewardWrapper}>
          {value.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    deleteClick(index);
                  }}
                >
                  X
                </button>
                <h2>{item.category.name}</h2>
                <h3>{item.operation.name}</h3>
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const ReferenceInput = ({ name, data, addData, deleteClick }) => {
  const [info, setInfo] = useState({
    reference: "",
    caption: "",
    detail: "",
  });
  const lang = useSelector((state) => state.settings.setting.lang);

  return (
    <label className={`${style.input} ${style.email}`}>
      <h5>{LangData[lang].form.reference}</h5>
      <Field
        type={"text"}
        id={name}
        name={name}
        placeholder={LangData[lang].form.referencePlaceholder}
        value={info.reference}
        onChange={(e) => {
          setInfo({
            ...info,
            reference: e.target.value,
          });
        }}
      />
      <Field
        type={"text"}
        id={name}
        name={name}
        placeholder={LangData[lang].form.referenceTitle}
        value={info.detail}
        onChange={(e) => {
          setInfo({
            ...info,
            detail: e.target.value,
          });
        }}
      />
      <Field
        type={"text"}
        id={name}
        name={name}
        placeholder={LangData[lang].form.referenceCaption}
        value={info.caption}
        onChange={(e) => {
          setInfo({
            ...info,
            caption: e.target.value,
          });
        }}
      />

      <button
        onClick={async (e) => {
          await e.preventDefault();
          await addData(info);
          await setInfo({
            reference: "",
            caption: "",
            detail: "",
          });
        }}
        className={style.addButton}
      >
        {LangData[lang].form.addReference}
      </button>
      {data && data.length ? (
        <div className={style.rewardWrapper}>
          {data.map((item, index) => {
            return (
              <div className={style.rewardCard}>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    deleteClick(index);
                  }}
                >
                  X
                </button>
                <h2>{item.reference}</h2>
                <h3>{item.detail}</h3>
                <h4>{item.caption}</h4>
              </div>
            );
          })}
        </div>
      ) : null}
    </label>
  );
};

const CheckInput = ({ title, name, value, withDoc }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className={style.checkInput}>
      <div className={`${style.checkWrapper} ${value ? style.checked : null}`}>
        <Field type="checkbox" name={name} />
      </div>
      <h5 className={style.title} onClick={() => setOpen(!isOpen)}>
        {title}
      </h5>

      {isOpen && withDoc ? (
        <div className={style.doc}>
          <div className={style.bar}>
            <h5>{title}</h5>
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              X
            </button>
          </div>
          <iframe src={withDoc} />
        </div>
      ) : null}
    </div>
  );
};

export {
  DateInput,
  EmailInput,
  PhoneInput,
  PasswordInput,
  RememberMe,
  TextInput,
  TextAreaInput,
  SelectInput,
  RegionInput,
  CityInput,
  RewardInput,
  OperationInput,
  ReferenceInput,
  ProfileImageInput,
  FileAddInput,
  OperationAddInput,
  RewardWithFileInput,
  CheckInput,
};
